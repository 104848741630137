<template>
  <el-container class="work-container">
    <el-header class="header">
      <el-input class="searchInput" placeholder="请输入昵称、姓名或手机" prefix-icon="el-icon-search" v-model="searchVal"
        @keyup.enter.native="searchChange">
      </el-input>
      <router-link to="/msg-list" class="bell-box">
        <span></span>
        <img class="input-bell" src="@/assets/images/work/bell.png" alt="">
      </router-link>
    </el-header>
    <el-main class="body">
      <!-- 宫格导航 -->
      <el-row>
        <el-col :span="4" class="body-nav">
          <router-link to="/cash-reg">
            <img src="@/assets/images/work/shouyin.png" alt="">
            <div>开单收银</div>
          </router-link>
        </el-col>
        <el-col :span="4" class="body-nav">
          <!-- /add-reservation -->
          <router-link to="/reservation">
            <img src="@/assets/images/work/yuyue.png" alt="">
            <div>添加预约</div>
          </router-link>
        </el-col>
        <el-col :span="4" class="body-nav">
          <router-link to="/add-vip">
            <img src="@/assets/images/work/addVip.png" alt="">
            <div>添加会员</div>
          </router-link>
        </el-col>
        <el-col :span="4" class="body-nav">
          <router-link to="/echars-data">
            <img src="@/assets/images/work/shuju.png" alt="">
            <div>数据统计</div>
          </router-link>
        </el-col>
        <el-col :span="4" class="body-nav">
          <router-link to="/succession">
            <img src="@/assets/images/work/jiaoban.png" alt="">
            <div>交班管理</div>
          </router-link>
        </el-col>
        <el-col :span="4" class="body-nav">
          <router-link to="goods-management">
            <img src="@/assets/images/work/shangpin.png" alt="">
            <div>商品管理</div>
          </router-link>
        </el-col>
      </el-row>
      <!-- 内容 -->
      <div class="content" v-loading="loading">
        <h4>开单列表</h4>
        <el-row :gutter="10" v-infinite-scroll="load">
          
          <el-col class="content-box" :span="rowCol" v-for="(item,i) in faceList" :key="i">
            <div class="content-list" 
            :class="item.status=='服务中'?'content-list1':(item.status=='已完成'?'content-list2':'')"
             @click="jumpDetail(item.id,item.status)">
              <el-row class="title">
                <el-col :span="14" class="img">
                  <img :src="$getimgsrc(item.user.avatar)" alt="" style="border-radius:50%;">
                  <div>
                    {{ item.user.realname }}
                    <div>{{ item.user.mobile }}</div>
                  </div>
                </el-col>
                <el-col :span="10" class="time">
                  <span>面诊人：{{ item.manystoreperson_id }}</span>
                </el-col>
              </el-row>
              <div style="height: 120px;">
                <el-row class="list" v-for="(item2,index2) in item.shop" :key="index2" v-show="index2<3">
                  <el-col :span="18" lass="list-l">
                    {{ item2.shopname }}
                  </el-col>
                  <el-col :span="6" class="list-r">
                    ¥ {{ item2.unit_price }}
                  </el-col>
                </el-row>
              </div>
              
              <el-row class="list1">
                <el-col :span="18" class="list-l">
                  ......
                </el-col>
                <el-col :span="6" class="list-r">
                  共{{item.shop.length}}项
                </el-col>
              </el-row>
              <el-row class="list2">
                <el-col :span="12" class="list-l">
                  合计
                </el-col>
                <el-col :span="12" class="list-r">
                  ¥{{ item.total_price }}
                </el-col>
              </el-row>
            </div>
          </el-col>


        </el-row>
        <empty title="暂无开单信息" v-if="faceList.length == 0"></empty>
        <!-- <el-row :gutter="10">
          <el-col class="content-box" :span="rowCol" v-for="(item1,i1) in 4" :key="i1">
            <div class="content-list content-list1" @click="jumpDetail('服务中')">
              <el-row class="title">
                <el-col :span="14" class="img">
                  <img src="@/assets/images/avatar.png" alt="">
                  <div>
                    赵小美
                    <div>15858585858</div>
                  </div>
                </el-col>
                <el-col :span="10" class="time">
                  <span>今天 18:00</span>
                </el-col>
              </el-row>
              <el-row class="list">
                <el-col :span="18" lass="list-l">
                  头皮平衡补水精华
                </el-col>
                <el-col :span="6" class="list-r">
                  ¥ 99.00
                </el-col>
              </el-row>
              <el-row class="list">
                <el-col :span="18" lass="list-l">
                  头皮平衡补水精华
                </el-col>
                <el-col :span="6" class="list-r">
                  ¥ 99.00
                </el-col>
              </el-row>
              <el-row class="list">
                <el-col :span="18" lass="list-l">
                  头皮平衡补水精华
                </el-col>
                <el-col :span="6" class="list-r">
                  ¥ 99.00
                </el-col>
              </el-row>
              <el-row class="list1">
                <el-col :span="18" class="list-l">
                  ......
                </el-col>
                <el-col :span="6" class="list-r">
                  共5项
                </el-col>
              </el-row>
              <el-row class="list2">
                <el-col :span="12" class="list-l">
                  合计
                </el-col>
                <el-col :span="12" class="list-r">
                  ¥ 399.00
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row> -->
        <!-- <el-row :gutter="10">
          <el-col class="content-box" :span="rowCol" v-for="(item2,i2) in 4" :key="i2">
            <div class="content-list content-list2" @click="jumpDetail('已关闭')">
              <el-row class="title">
                <el-col :span="14" class="img">
                  <img src="@/assets/images/avatar.png" alt="">
                  <div>
                    赵小美
                    <div>15858585858</div>
                  </div>
                </el-col>
                <el-col :span="10" class="time">
                  <span>今天 18:00</span>
                </el-col>
              </el-row>
              <el-row class="list">
                <el-col :span="18" lass="list-l">
                  头皮平衡补水精华
                </el-col>
                <el-col :span="6" class="list-r">
                  ¥ 99.00
                </el-col>
              </el-row>
              <el-row class="list">
                <el-col :span="18" lass="list-l">
                  头皮平衡补水精华
                </el-col>
                <el-col :span="6" class="list-r">
                  ¥ 99.00
                </el-col>
              </el-row>
              <el-row class="list">
                <el-col :span="18" lass="list-l">
                  头皮平衡补水精华
                </el-col>
                <el-col :span="6" class="list-r">
                  ¥ 99.00
                </el-col>
              </el-row>
              <el-row class="list1">
                <el-col :span="18" class="list-l">
                  ......
                </el-col>
                <el-col :span="6" class="list-r">
                  共5项
                </el-col>
              </el-row>
              <el-row class="list2">
                <el-col :span="12" class="list-l">
                  合计
                </el-col>
                <el-col :span="12" class="list-r">
                  ¥ 399.00
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row> -->
      </div>
    </el-main>
    <div class="fixed">
      <div class="type1"><i></i>待支付({{ allNumber.count1 }})</div>
      <div class="type2"><i></i>服务中({{ allNumber.count2}})</div>
      <div class="type3"><i></i>已完成({{ allNumber.count3}})</div>
      <div class="ref" @click="Refresh"><i class="el-icon-refresh"></i></div>
    </div>
  </el-container>
</template>

<script>
  export default {
    data() {
      return {
        loading:false,
        searchVal: '',
        innerWidth: document.documentElement.clientWidth,
        rowCol: 6,
        page:0,
        faceList:[],
        allNumber:0,
      }
    },

    watch: {
      innerWidth(val) {
        if (val >= 1440) {
          this.rowCol = 6
        } else if (val >= 1150) {
          this.rowCol = 8
        } else if (val >= 830) {
          this.rowCol = 12
        } else if (val <= 830) {
          this.rowCol = 12
        }
      }
    },
    mounted() {
      // this.$store.commit('setToken','123456789op')
      // console.log(this.$store.state.token)
      var that = this;
      if (that.innerWidth >= 1440) {
        this.rowCol = 6
      } else if (that.innerWidth >= 1150) {
        this.rowCol = 8
      } else if (that.innerWidth >= 830) {
        this.rowCol = 12
      } else if (that.innerWidth <= 830) {
        this.rowCol = 12
      }
      window.onresize = () => {
        return (() => {
          window.fullWidth = document.documentElement.clientWidth;
          that.innerWidth = window.fullWidth; // 宽
        })()
      };
    },
    created () {
      this.getNumber()
      this.getFaceList()
    },
    methods: {
      //获取总条数
      getNumber (){
        this.$http.post('api/store/index/changdu').then(res =>{
          this.allNumber = res.data.data
        })
      },
      //刷新
      Refresh(){
        this.page = 0
        this.faceList = []
        this.searchVal = ''
        this.getFaceList()
      },
      //分页
      load(){
        if(this.faceList.length < (this.page+1)*10)return
        this.page++
        this.getFaceList()
      },
      //订单列表
      getFaceList(){
        this.loading = true
        this.$http.post('api/store/index/index',{
          page:this.page,
          keyword:this.searchVal
        }).then(res =>{
          this.faceList = [...this.faceList,...res.data.data]
          this.loading = false
        }).catch(err =>{
          this.loading = false
        })
      },
      //跳转订单详情
      jumpDetail(id,pageName) {
        this.$router.push({
          path: '/faceorder-detail',
          query: {
            id,
            pageName,
          }
        })
        // this.$router.push({
        //   name: 'OrderDetail',
        //   params: {
        //     pageName,
        //   }
        // })
      },
      // 搜索
      searchChange() {
        if (this.searchVal.trim()) {
          this.faceList = []
          this.page = 0
          this.getFaceList()
          // console.log(this.searchVal.trim());
        } else {
          this.$message.error('搜索内容不能为空')
        }
      },
    }
  }

</script>

<style lang="less" scoped>
  .work-container {
    height: (100vh);
    background-color: #f9f9f9;


    .header {
      position: relative;
      width: 100%;
      text-align: center;
      border-bottom: 1px solid #e9e9e9;
      background-color: #fff;

      .searchInput {
        width: 40%;
        margin: 10px 0;
      }

      .bell-box {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;

        span {
          position: absolute;
          top: -5px;
          right: 0;
          width: 8px;
          height: 8px;
          background-color: red;
          border-radius: 100%;
        }

        img {
          width: 24px;
        }
      }

    }

    .body {

      .body-nav {
        text-align: center;
        padding: 20px 0;
        font-size: 14px;
        border-bottom: 1px solid #e9e9e9;
        background-color: #fff;

        img {
          width: 50px;
          border-radius: 5px;
          margin-bottom: 5px;
        }
      }

      .content {
        padding: 20px;

        h4 {
          margin: 0 0 20px;
        }

        .content-box {
          margin: 5px 0;

          .content-list {
            background-color: #fff;
            border-top: 3px solid #ff7a7a;

            img {
              width: 100%;
              max-width: 40px;
              min-width: 30px;
              vertical-align: middle;
              margin-right: 10px;
            }

            .img {
              display: flex;
              justify-content: flex-start;
            }

            .title {
              padding: 10px;

              // .name {}

              .time {

                text-align: right;
                line-height: 40px;
                color: #ff7a7a;

                span {
                  border: 1px solid #ff7a7a;
                  padding: 5px 5px;
                  font-size: 12px;
                  border-radius: 5px;
                }
              }
            }

            .list {
              padding: 10px;
              border-bottom: 1px solid #f2f2f2;
            }

            .list1 {
              padding: 10px;
            }

            .list2 {
              padding: 10px;
              background-color: #f2f2f2;
            }

            .list .list-r,
            .list1 .list-r,
            .list2 .list-r {
              text-align: right;
              color: #999;
            }

            .list2 .list-r {
              color: #f56c6c;
              font-weight: bold;
            }
          }

          .content-list1 {
            border-top: 3px solid #fec03d;

            .title {

              .time {
                color: #fec03d;

                span {
                  border: 1px solid #fec03d;

                }
              }
            }
          }

          .content-list2 {
            border-top: 3px solid #82d588;

            .title {
              .time {
                color: #82d588;

                span {
                  border: 1px solid #82d588;

                }
              }
            }
          }
        }
      }
    }
  }

  .fixed {
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 9;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 240px;
    height: 30px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, .5);
    color: #fff;
    font-size: 12px;

    .type1,
    .type2,
    .type3 {
      i {
        width: 8px;
        height: 8px;
        margin-right: 3px;
        border-radius: 2px;
        display: inline-block;
      }
    }

    .type1 {
      i {
        background-color: #ff7a7a;
      }
    }

    .type2 {
      i {
        background-color: #ffa94c;
      }
    }

    .type3 {
      i {
        background-color: #82d588;
      }
    }

    .ref {
      font-size: 16px;
    }
  }

  /deep/ .el-input__inner {
    height: 35px;
    border-radius: 80px;

    &:focus {
      border-color: #dcdfe6;
    }
  }

  /deep/ .el-input__prefix {
    .el-input__icon {
      line-height: 36px;
    }
  }

  /deep/ .el-main {
    padding: 0 !important;
  }

</style>
